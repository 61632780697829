<template>
  <div>
    <Header :title="title" />
    <div class="mt-3 pl-4 pr-2">
      <b-row>
        <b-col sm="9">
          <card-first />
        </b-col>
        <b-col sm="3">
          <card-waiting />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/Header";
import { BRow, BCol, BContainer } from "bootstrap-vue";
import CardFirst from "./CardFirst";
import CardWaiting from "./CardWaiting";
import { optionsMqtt } from "@/config";
import mqtt from "mqtt";

export default {
  components: {
    Header,
    BRow,
    BCol,
    CardFirst,
    CardWaiting,
  },
  data() {
    return {
      title: "",
      brand: this.$route.params.sede,
      area: this.$route.params.area,
    };
  },
  methods: {
    startMqttClient(){
      const me = this;

      const topic = "helpsmart/+/#";
      
      const connectUrl = "ws://" + optionsMqtt.host + ":" + optionsMqtt.port + optionsMqtt.endpoint;

      try {
        me.client = mqtt.connect(connectUrl, optionsMqtt);
      } catch (error) {
        console.error(error);
      }

      me.client.on("connect", () => {
        console.log("Connection succeeded");

        me.client.subscribe(topic, {qos:0}, (err) => {
          if(err){
            console.log("Error in subscription", err);
            return;
          }

          console.log("Subscription success");
        })
      });

      me.client.on("error", error => {
        console.log("Connection failed", error);
      });

      me.client.on("reconnect", (error) => {
        console.log("Reconnecting", error);
      });

      me.client.on("message", (topic, message) => {
        
        const messageReceived = JSON.parse(message.toString())
        if (!messageReceived.sensor) {
          me.$store.dispatch("message/loadMessages", messageReceived);
          me.$router.push({name: "call", params: { type: messageReceived.llamado[0].name}})
        }
      });
    }
  },
  mounted() {
    const me = this;

    // me.startMqttClient();
    me.title =
      me.brand.split("_").join(" ") + " - " + me.area.split("_").join(" ");
  },
};
</script>

<style></style>
