<template>
  <div>
    <b-card no-body class="p-2 card_first" :class="shadowColor(called.llamado)">
      <div class="header_first d-flex justify-content-between">
        <div class="" v-if="!isIncendio">
          <div class="name_header mb-0">Gustavo Adolfo Castañes Orta</div>
          <div class="title_riesk_first text-left">Riesgos</div>
          <div class="d-flex">
            <div class="bar_rish_riesk_first" />
            <div class="bar_rish_riesk_first" />
            <div class="bar_rish_riesk_first" />
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <img
            src="@/assets/images/icontemperatura.png"
            class="icon_temperature"
          />
          <span class="title_icon_temp">24ºC</span>
        </div>
      </div>
      <div class="panel_first">
        <div class="d-flex justify-content-around">
          <div v-if="!isIncendio">
            <div class="panel_left_first">
              <div class="box_panel_first_round">
                <div class="title_box_panel_first text-center">Ronda</div>
                <div
                  class="d-flex justify-content-lg-center align-items-center"
                >
                  <div class="round" />
                </div>
              </div>
              <div class="box_panel_first">
                <div class="title_box_panel_first text-center">Alimento</div>
                <div class="d-flex justify-content-center flex-column">
                  <div class="box_food2 active">Desayuno</div>
                  <div class="box_food2 active">Almuerzo</div>
                  <div class="box_food2">Cena</div>
                </div>
              </div>
              <div class="box_panel_first_exam">
                <div class="title_box_panel_first text-center">Exámen</div>
                <div class="text-center">
                  <apexchart
                    id="radiar_bar_first"
                    :options="exams"
                    width="180"
                    :series="series"
                  />
                  <span class="title_radiar1_first">2/4</span>
                </div>
              </div>
              <div class="box_panel_first_exam">
                <div class="title_box_panel_first text-center">Medicamento</div>
                <div class="text-center">
                  <apexchart
                    id="radiar_bar_first"
                    :options="medicines"
                    width="180"
                    :series="series"
                  />
                  <span class="title_radiar2_first">2/4</span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <img class="img_panel_first" :src="img" alt="" />
          </div>
          <div class="panel_right_first">
            <h1 class="text_code">{{ nameCalled }}</h1>
            <div class="text_bed">HAB_302</div>
            <div class="text-center">
              <apexchart
                type="radialBar"
                id="radiarBar_time"
                height="350"
                :options="chartOptions"
                :series="series2"
              ></apexchart>
              <div class="time">
                <div class="time_">3:30</div>
                <div class="text_time">
                  Tiempo transcurrido
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  components: {
    BCard,
  },
  data() {
    return {
      exams: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "40%",
            },
            track: {
              show: true,
              background: "#d2d2d2",
            },
            dataLabels: {
              show: false,
            },
          },
        },
        labels: [""],
        colors: ["#28c76f"],
      },
      medicines: {
        chart: {
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "40%",
            },
            track: {
              show: true,
              background: "#d2d2d2",
            },
            dataLabels: {
              show: false,
            },
          },
        },
        labels: [""],
        colors: ["#35b0db"],
      },
      series: [50],
      nameCalled: null,
      called: {},
      series2: [100],
      chartOptions: {
        plotOptions: {
          radialBar: {
            size: 150,
            offsetY: 20,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "65%",
            },
            track: {
              background: "#fff",
              strokeWidth: "100%",
            },
            dataLabels: {
              show: false,
            },
          },
        },
        colors: ["#ea5455"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#28c76f"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          dashArray: 8,
        },
        labels: [""],
      },
      img: null,
      isIncendio: false,
    };
  },
  computed: {
    ...mapState("message", ["messages"]),
  },
  watch: {
    messages() {
      this.renderCalled();
    },
  },
  methods: {
    shadowColor(item) {
      const me = this;
      let styleColor = "";

      if (item) {
        const name = item[0].name;

        if (name == "Paciente") {
          styleColor = "patient";
          me.isIncendio = false;
          me.nameCalled = "PACIENTE";
          me.img = require("@/assets/images/llamadopaciente.gif");
        } else if (name == "Incendio") {
          me.nameCalled = "INCENDIO";
          me.isIncendio = true;
          styleColor = "orange";
          me.img = require("@/assets/images/incendiollamado.gif");
        } else if (name == "Rojo") {
          styleColor = "red";
          me.isIncendio = false;
          me.nameCalled = "CÓDIGO ROJO";
          me.img = require("@/assets/images/codigorojo.gif");
        } else {
          styleColor = "blue";
          me.isIncendio = false;
          me.nameCalled = "CÓDIGO AZUL";
          me.img = require("@/assets/images/codigoazul.gif");
        }
      }
      return styleColor;
    },
    renderCalled() {
      const me = this;

      me.messages.forEach((el, index) => {
        setTimeout(() => {
          me.loadCalled(el, index);
        }, index * 3000);
      });
    },
    loadCalled(el, index) {
      const me = this;
      me.called = el;

      if (index == me.messages.length) {
        me.renderCalled();
      }
    },
  },
  beforeRouteUpdate(to, from, next) {},
  mounted() {
    const me = this;
    me.renderCalled();
  },
};
</script>

<style lang="css">
.card_first {
  box-shadow: 0 4px 15px 0 white;
  background-color: white;
  border: none;
}

.card_first.red {
  box-shadow: 0 4px 15px 10px rgba(234, 84, 85, 1);
}

.card_first.patient {
  box-shadow: 0 4px 15px 10px rgba(0, 204, 153, 1);
}

.card_first.orange {
  box-shadow: 0 4px 15px 10px rgba(225, 149, 99, 1);
}

.card_first.blue {
  box-shadow: 0 4px 15px 10px rgba(41, 162, 204, 1);
}

.header_first {
  background-color: white;
  height: 85px;
}

.name_header {
  font-size: 32px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.title_riesk_first {
  font-size: 16px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
  position: relative;
  top: -5px;
}

.bar_rish_riesk_first {
  padding: 3px;
  border-radius: 50px;
  background-color: #7367f0;
  margin-right: 5px;
  width: 40px;
  position: relative;
  top: -10;
}

.title_icon_temp {
  font-size: 32px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.icon_temperature {
  position: relative;
  left: -5px;
  color: #5b5b5b;
  height: 40px;
}

.padding_2 {
  padding: 8px;
}

.panel_first {
  height: 380px;
  padding: 5px;
}

.panel_left_first {
  height: 380px;
}

.box_panel_first {
  height: 95px;
}

.box_panel_first_round {
  height: 70px;
}

.box_panel_first_exam {
  height: 100px;
}

.title_box_panel_first {
  font-size: 18px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.round {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #b2b2b2;
  position: relative;
  top: 3px;
}

#radiar_bar_first {
  display: flex;
  justify-content: center;
  position: relative;
  top: -25px;
  margin-bottom: -60px;
  margin-right: -40px;
  margin-left: -40px;
}

.title_radiar1_first {
  position: relative;
  top: -45px !important;
  color: #28c76f;
  font-weight: 700;
}

.title_radiar2_first {
  position: relative;
  top: -45px !important;
  color: #35b0db;
  font-weight: 700;
}

.box_food2 {
  background: #b2b2b2;
  width: 100%;
  height: 20px;
  margin-bottom: 2px;
  font-size: 14px;
  color: white;
  border-radius: 0.2rem;
  text-align: center;
}

.box_food2.active {
  background-color: #28c76f;
}

.img_panel_first {
  height: 380px;
  position: relative;
  top: -30px;
}

.panel_right_first {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.text_code {
  font-size: 48px;
  color: #5b5b5b;
  letter-spacing: 1px;
  font-family: var(--fuente-montserrat);
}
.text_bed {
  font-size: 28px;
  color: #5b5b5b;
  letter-spacing: 1px;
  font-family: var(--fuente-montserrat);
  position: relative;
  top: -15px;
}

#radiarBar_time {
  position: relative;
  top: -60px;
}
.time {
  position: relative;
  top: -250px;
}

.time_ {
  font-size: 48px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
  font-weight: 700;
}

.text_time {
  font-size: 18px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
  font-weight: 700;
}


.flicker__ {
  animation-name: flicker;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name: flicker;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}

@-moz-keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.9;
    background-color: #747373;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.9;
    background-color: #747373;
  }
  100% {
    opacity: 1;
  }
}

@keyframes flicker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.9;
    background-color: #747373;
  }
  100% {
    opacity: 1;
  }
}
</style>
