<template>
  <div>
    <div>
      <div class="box_waiting mb-3 text-left d-flex">
        <span class="title_box_waiting"> Llamados en espera </span>
        <div class="box_circle">
          {{ messages.length }}
        </div>
      </div>
    </div>
    <div class="mb-2" v-for="(item, index) in messages" :key="index">
      <div class="header__waiting" :class="shadowColor(item.llamado)">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <div class="title_header_waiting">HAB_101</div>
            <div class="title_riesk_waiting">Riesgos</div>
            <div class="d-flex">
              <div class="bar_riesk_waiting" />
              <div class="bar_riesk_waiting" />
              <div class="bar_riesk_waiting" />
            </div>
          </div>
          <div>
            <img src="@/assets/images/icontemperatura.png" class="icon_temperature_waiting" />
            <span class="title_icon_temp_waiting">24ºC</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {} from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState("message", ["messages"]),
  },
  methods: {
    shadowColor(item) {
      let styleColor = "";
      const name = item[0].name;

      if (name == "Paciente") {
          styleColor = "patient";
        } else if (name == "Incendio") {
          styleColor = "orange";
        } else if (name == "Rojo") {
          styleColor = "red";
        } else {
          styleColor = "blue";
        }

      return styleColor;
    },
  },
};
</script>

<style lang="css">
/* .card_ {
  border: 1.5px solid #6e6b7b;
  border-bottom-left-radius: .6rem;
  border-bottom-right-radius: .6rem;
} */

.header__waiting {
  background-color: var(--white-color) !important;
  border-radius: .3rem;
  padding: 4px;
  height: 59px;
  box-shadow: -1px -7px 15px -6px white;
}

.header__waiting.red {
  box-shadow: -1px -7px 15px -6px rgba(234, 84, 85, 1);
}

.header__waiting.patient {
  box-shadow: -1px -7px 15px -6px rgba(32, 214, 228, 1);
}

.header__waiting.orange {
  box-shadow: -1px -7px 15px -6px rgba(225, 149, 99, 1);
}

.header__waiting.blue {
  box-shadow: -1px -7px 15px -6px rgba(41, 162, 204, 1);
}

.icon_temperature_waiting {
  position: relative;
  left: -2px;
  height: 30px;
}

.padding__waiting {
  padding: 5px;
}

.title_header_waiting {
  font-size: 18px;
  color: #5b5b5b;
  font-weight: 700;
  font-family: var(--fuente-montserrat);
}

.title_riesk_waiting {
  font-size: 12px;
  color: #5b5b5b;
  font-weight: 700;
  font-family: var(--fuente-montserrat);
  position: relative;
  top: -5px;
}

.bar_riesk_waiting {
  padding: 4px;
  border-radius: .2rem;
  background-color: #5b5b5b;
  margin-right: 5px;
  width: 25px;
  position: relative;
  top: -5px;
}

.box_waiting {
  padding: 10px;
  border-radius: .3rem;
  background-color: #eaeaea;
  box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3), 0 4px 15px 0 rgba(133, 132, 132, 0.15);
}

.title_box_waiting {
  font-size: 22px;
  font-weight: 900;
  margin-right: 10px;
  font-family: var(--fuente-montserrat);
}

.box_circle {
  background-color: #5b5b5b;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: white;
  font-size: 20px;
  font-family: var(--fuente-montserrat);
  text-align: center;
}

.title_icon_temp_waiting {
  font-size: 18px;
  color: #5b5b5b;
  font-family: var(--fuente-montserrat);
}

.btn_icon {
  padding: 5px;
  font-size: 14px;
  background-color: #5b5b5b;
  color: var(--black-color);
}

.header__waiting.blue {
  background-color: #256eff;
}

.header__waiting.red {
  background-color: #E2252B;
}

.header__waiting.orange {
  background-color: #fe621d;
}

.header__waiting.green {
  background-color: #088044;
}
</style>
